import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

import { Auth, I18n as AmplifyI18n } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import {
  courses as courseQuerys,
  classrooms as classroomQuerys,
  users as userQuerys,
} from "@nualang/nualang-api-and-queries/Queries";
import AppContainer from "@nualang/nualang-ui-components/dist/Containers/App";
// eslint-disable-next-line max-len
import EnrollmentKeyDialog from "@nualang/nualang-ui-components/dist/Dialogs/EnrollmentKeyDialog/EnrollmentKeyDialog";
import ShareDrawer from "@nualang/nualang-ui-components/dist/Misc/ShareButton/ShareDrawer";
import ModeratedDialog from "@nualang/nualang-ui-components/dist/Dialogs/ModeratedDialog/ModeratedDialog";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import Common from "./Common";
import logo from "../img/logo/nualang-logo-white.svg";
import { AppContext } from "../context/AppContext";
import navigation from "../_nav";
import i18n from "../i18n";
import Loading from "../components/Misc/Loading";
import AcceptTerms from "../components/AcceptTerms/AcceptTerms";
import coursesContext from "../context/CoursesContext";
import UsersContext from "../context/UsersContext";
import classroomsContext from "../context/ClassroomsContext";
import { ColorModeContext } from "../themes/ThemeProviders/ColorModeContext";
import * as router from "react-router-dom";
import config from "../config";
import { createClassroomMember } from "@nualang/nualang-api-and-queries/APIs/ClassroomMembers";
import { createCourseMember } from "@nualang/nualang-api-and-queries/APIs/CourseMembers";
import { getUser } from "@nualang/nualang-api-and-queries/APIs/Users";
import whatsNewMarkdown from "../WhatsNew.md";



const MainContent = ({
  children,
  isBasicPlan,
  authenticated,
  authState,
  authData,
}) => {
  const CoursesContext = useContext(coursesContext);
  const ClassroomsContext = useContext(classroomsContext);
  const appContext = useContext(AppContext);
  const colorMode = useContext(ColorModeContext);

  const {
    joinCourseDialogOpen,
    handleClosePrivateCourseDialog,
    newCourse,
    setNewCourse,
  } = CoursesContext;
  const {
    joinClassroomDialogOpen,
    handleClosePrivateClassroomDialog,
    newClassroom,
    setNewClassroom,
  } = ClassroomsContext;
  const usersContext = useContext(UsersContext);
  const {} = usersContext;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const {
    setUser,
    user,
    isSnackbarOpen,
    closeSnackbar,
    message,
    type,
    loading,
    shareUrl,
    shareScreenOpen,
    handleCloseShare,
    subscription,
    isModerateDialogOpen,
    handleCloseModerateDialog,
    openSnackbar,
    startLoading,
    stopLoading,
    isCollapsed,
    setIsCollapsed,
    moderationReason,
  } = appContext;

  const [readableMarkdown, setReadableMarkdown] = useState({ md: "" });
  const [whatsNewDate, setWhatsNewDate] = useState("");

  // Mutations
  const joinClassroomMutation = classroomQuerys.useJoinClassroom(
    createClassroomMember,
    {
      onSettled: (data, error, variables, context) => {
        // Error or success... doesn't matter!
        stopLoading();
      },
      onSuccess: async (data, variables, context) => {
        openSnackbar("joined_classroom", "success");
        queryClient.invalidateQueries({
          queryKey: classroomQuerys.classroomKeys.itemMembers(
            variables.classroomId,
            variables.username,
          ),
        });
        queryClient.invalidateQueries({
          queryKey: classroomQuerys.classroomKeys.itemMember(
            variables.classroomId,
            variables.username,
            variables.username,
          ),
        });
        queryClient.invalidateQueries({
          queryKey: classroomQuerys.classroomKeys.list({
            limit: config.limits.teachClassrooms,
            memberId: variables.username,
            verbose: false,
          }),
        });
        handleClosePrivateClassroomDialog();
        setNewClassroom("");
      },
      onError: (error, variables, context) => {
        openSnackbar("problem", "error");
      },
    },
  );
  const joinCourseMutation = courseQuerys.useJoinCourse(createCourseMember, {
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
      stopLoading();
    },
    onSuccess: async (data, variables, context) => {
      openSnackbar("joined_course", "success");
      queryClient.invalidateQueries({
        queryKey: courseQuerys.courseKeys.itemMembers(
          variables.courseId,
          variables.username,
        ),
      });
      queryClient.invalidateQueries({
        queryKey: courseQuerys.courseKeys.itemMember(
          variables.courseId,
          variables.username,
          variables.username,
        ),
      });
      queryClient.invalidateQueries({
        queryKey: courseQuerys.courseKeys.list({
          memberId: authData.username,
          verbose: false,
        }),
      });
      handleClosePrivateCourseDialog();
      setNewCourse("");
    },
    onError: (error, variables, context) => {
      openSnackbar("problem", "error");
    },
  });

  const currentLanguage = i18n.language;

  // const title = newCourse ? t('join_course') : t('join_classroom');

  const joinClassroom = async (classroomId, enrollmentKey) => {
    startLoading();
    return joinClassroomMutation.mutateAsync({
      classroomId,
      username: authData.username,
      enrollmentKey,
    });
  };

  const handlePrivateClassroomSubmit = async (enrollmentKey) => {
    try {
      const { allowedDomains = [] } = newClassroom;
      // if there's no allowed domains specified then allow access
      let domainMatch = !(allowedDomains.length > 0);
      for (let index = 0; index < allowedDomains.length; index += 1) {
        const domain = allowedDomains[index];
        if (authData.attributes.email.endsWith(domain)) {
          domainMatch = true;
        }
      }
      if (!domainMatch) {
        openSnackbar(
          `You cannot join this classroom without having one of the allowed email domains: ${allowedDomains.toString()}`,
          "error",
        );
      } else {
        const response = await joinClassroom(
          newClassroom.classroomId,
          enrollmentKey,
        );
        if (
          response.Item.classroomId !== null ||
          response.Item.classroomId !== undefined
        ) {
          return newClassroom.classroomId;
        }
      }
    } catch (error) {
      console.error(error);
      if (!enrollmentKey || enrollmentKey !== newClassroom.enrolmentKey) {
        openSnackbar("Incorrect Key", "error");
      } else {
        openSnackbar(error.message, "error");
      }
    }
  };

  const joinCourse = async (courseId, enrollmentKey) => {
    startLoading();
    return joinCourseMutation.mutateAsync({
      courseId,
      username: authData.username,
      enrollmentKey,
    });
  };

  const handlePrivateCourseSubmit = async (enrollmentKey) => {
    try {
      const { allowedDomains = [] } = newCourse;
      // if there's no allowed domains specified then allow access
      let domainMatch = !(allowedDomains.length > 0);
      for (let index = 0; index < allowedDomains.length; index += 1) {
        const domain = allowedDomains[index];
        if (authData.attributes.email.endsWith(domain)) {
          domainMatch = true;
        }
      }
      if (!domainMatch) {
        openSnackbar(
          `You cannot join this course without having one of the allowed email domains: ${allowedDomains.toString()}`,
          "error",
        );
      } else {
        const response = await joinCourse(newCourse.courseId, enrollmentKey);
        if (
          response.Item.courseId !== null ||
          response.Item.courseId !== undefined
        ) {
          return newCourse.courseId;
        }
      }
    } catch (error) {
      console.error(error);
      if (!enrollmentKey || enrollmentKey !== newClassroom.enrolmentKey) {
        openSnackbar("Incorrect Key", "error");
      } else {
        openSnackbar(error.message, "error");
      }
    }
  };

  const handleCourseDialogSubmit = async (key) => {
    const response = await handlePrivateCourseSubmit(key);
    if (response && window.location.pathname !== `/courses/${response}`) {
      if (window.location.pathname.startsWith("/classrooms")) {
        const pathname = window.location.pathname;
        const splitPath = pathname.split('/');
        const classroomId = splitPath[2];
        window.location.replace(`/classrooms/${classroomId}/${response}`);
      } else {
        window.location.replace(`/courses/${response}`);
      }
    }
  };

  const handleClassroomDialogSubmit = async (key) => {
    const response = await handlePrivateClassroomSubmit(key);
    if (response && window.location.pathname !== `/classrooms/${response}`) {
      window.location.replace(`/classrooms/${response}`);
    }
  };

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language);
    AmplifyI18n.setLanguage(language);
  }

  useEffect(() => {
    const themePreference = localStorage.getItem("nualang-theme");
    if (
      !themePreference &&
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // eslint-disable-next-line no-console
      colorMode.toggleColorMode();
    }
  }, [authData?.username, colorMode]); // Only run on mount

  useEffect(() => {
    setUser(authData);
    // If user has no role selected direct them to the onboarding steps
  }, [authState, authData, setUser]); // Only re-run the effect if the authState, authData changes

  useEffect(() => {
    const regex = /: <> \(([^\)]+)\)/i;
    fetch(whatsNewMarkdown)
      .then((res) => res.text())
      .then((md) => {
        setReadableMarkdown({ md });
        setWhatsNewDate(md.match(regex)[1]);
      });
  }, []);

  const userQuery = userQuerys.useUser(
    async (userId) => {
      const response = await getUser(userId);
      return response;
    },
    {
      userId: authData && authData.username,
    },
    {
      enabled: !!(authData && authData.username),
    },
  );
  const currentUser =
    userQuery.isSuccess && userQuery.data ? userQuery.data : {};

  const isTeacher = authData.attributes["custom:role"] === "teach";

  return (
    <>
      {user && (
        <AppContainer
          t={t}
          logoSrc={logo}
          toggleTheme={colorMode.toggleColorMode}
          user={user.attributes}
          // handleSignOut={signOut}
          handleSignOut={() => Auth.signOut()}
          navigation={
            isTeacher ? navigation.teachSideNav : navigation.learnSideNav
          }
          bottomNav={
            isTeacher ? navigation.teachBottomNav : navigation.learnBottomNav
          }
          isSnackbarOpen={isSnackbarOpen}
          closeSnackbar={closeSnackbar}
          snackbarMessage={message}
          snackbarType={type}
          currentLanguage={currentLanguage}
          handleChangeLanguage={handleChangeLanguage}
          navToSettings={"/settings"}
          isBasicPlan={isBasicPlan}
          subscription={subscription}
          upgradePlanURL={`${config.SITE_URL}/pricing/${
            subscription && subscription.plan && subscription.plan !== "basic"
              ? "upgrade"
              : "app"
          }`}
          authenticated={authenticated}
          whatsNewMarkdown={readableMarkdown.md}
          whatsNewDate={whatsNewDate}
          verificationStatus={
            currentUser.verificationStatus ? currentUser.verificationStatus : ""
          }
          gameAward={currentUser.gameAward ? currentUser.gameAward : ""}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        >
          {children}
        </AppContainer>
      )}
      {loading ? <Loading /> : null}
      <EnrollmentKeyDialog
        t={t}
        open={joinClassroomDialogOpen}
        handleClose={handleClosePrivateClassroomDialog}
        handleSubmit={handleClassroomDialogSubmit}
        dialogTitle={t("join_classroom")}
      />
      <EnrollmentKeyDialog
        t={t}
        open={joinCourseDialogOpen}
        handleClose={handleClosePrivateCourseDialog}
        handleSubmit={handleCourseDialogSubmit}
        dialogTitle={t("join_course")}
      />
      <ShareDrawer
        t={t}
        url={shareUrl}
        open={shareScreenOpen}
        handleClose={handleCloseShare}
      />
      <ModeratedDialog
        open={isModerateDialogOpen}
        handleClose={handleCloseModerateDialog}
        t={t}
        moderationReason={moderationReason}
      />
      <AcceptTerms t={t} username={authData.username} />
    </>
  );
};

const TemplateWrapper = ({
  children,
  authState,
  authData,
  isBasicPlan,
  authenticated,
}) => {
  return (
    <Common authData={authData}>
      <Router>
        <ScrollToTop
          disableLiveChat={
            authData &&
            authData.attributes &&
            authData.attributes["custom:role"] &&
            authData.attributes["custom:role"] === "learn"
          }
        >
          <MainContent
            isBasicPlan={isBasicPlan}
            authenticated={authenticated}
            authState={authState}
            authData={authData}
          >
            {children}
          </MainContent>
        </ScrollToTop>
      </Router>
    </Common>
  );
};

TemplateWrapper.propTypes = {
  authData: PropTypes.object,
  authState: PropTypes.string,
  appContext: PropTypes.object,
  courseContext: PropTypes.object,
  classroomContext: PropTypes.object,
  children: PropTypes.node,
  isBasicPlan: PropTypes.bool,
};

export default TemplateWrapper;
