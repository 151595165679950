import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools, } from "@tanstack/react-query-devtools";
import { DndProvider, Preview } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { Amplify, I18n, Hub } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { generatePreview } from "@nualang/nualang-ui-components/dist/Misc/DragAndDropPreview";
import "typeface-montserrat";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "animate.css/animate.min.css";
import "../styles/all.sass";
import UsersContextProvider from "../context/UsersContextProvider";
import CoursesContextProvider from "../context/CoursesContextProvider";
import ClassroomsContextProvider from "../context/ClassroomsContextProvider";
import RoleplaysContextProvider from "../context/RoleplaysContextProvider";
import { AppContextProvider } from "../context/AppContextProvider";
import BotsContextProvider from "../context/BotsContextProvider";
import FeedbackContextProvider from "../context/FeedbackContextProvider";
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import config from "../config";

import { trackRecommendedEvent, trackEvent } from "../utils/index";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const amplifyConfig = config.amplify;

/* check if env is localhost or not
if you're not developing on localhost,
you will need to detect this is another way—the docs linked above give some examples.
*/
const isLocalhost = !!(window.location.hostname === "localhost");

// split redirect signin and signout strings into correct URIs
const [productionRedirectSignIn, localRedirectSignIn] =
  amplifyConfig.oauth.redirectSignIn.split(",");
const [productionRedirectSignOut, localRedirectSignOut] =
  amplifyConfig.oauth.redirectSignOut.split(",");

// use correct URI in the right env
const updatedAmplifyConfig = {
  ...amplifyConfig,
  oauth: {
    ...amplifyConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
};

Amplify.configure(updatedAmplifyConfig);

I18n.putVocabularies(translations);

const dict = {
  en: {
    "Enter your Preferred Username": "Enter your Name",
    "UserMigration failed with error Bad credentials.":
      "Incorrect username or password.",
    "We Texted You": "We Emailed You",
    "Sign in": "Sign In",
  },
  de: {
    "UserMigration failed with error Bad credentials.":
      translations.de["Incorrect username or password."],
    "We Texted You": translations.de["We Emailed You"],
    "Sign in": translations.de["Sign In"],
  },
  es: {
    "UserMigration failed with error Bad credentials.":
      translations.es["Incorrect username or password."],
    "We Texted You": translations.es["We Emailed You"],
    "Sign in": translations.es["Sign In"],
  },
  fr: {
    "Preferred Username": "Nom",
    "Sign Up with Google": `S'inscrire  avec Google`,
    "UserMigration failed with error Bad credentials.":
      translations.fr["Incorrect username or password."],
    "We Texted You": translations.fr["We Emailed You"],
    "Sign in": translations.fr["Sign In"],
  },
  id: {
    "UserMigration failed with error Bad credentials.":
      translations.id["Incorrect username or password."],
    "We Texted You": translations.id["We Emailed You"],
    "Sign in": translations.id["Sign In"],
  },
  it: {
    "UserMigration failed with error Bad credentials.":
      translations.it["Incorrect username or password."],
    "We Texted You": translations.it["We Emailed You"],
    "Sign in": translations.it["Sign In"],
  },
  ja: {
    "UserMigration failed with error Bad credentials.":
      translations.ja["Incorrect username or password."],
    "We Texted You": translations.ja["We Emailed You"],
    "Sign in": translations.ja["Sign In"],
  },
  kr: {
    "UserMigration failed with error Bad credentials.":
      translations.kr["Incorrect username or password."],
    "We Texted You": translations.kr["We Emailed You"],
    "Sign in": translations.kr["Sign In"],
  },
  nl: {
    "UserMigration failed with error Bad credentials.":
      translations.nl["Incorrect username or password."],
    "We Texted You": translations.nl["We Emailed You"],
    "Sign in": translations.nl["Sign In"],
  },
  pl: {
    "UserMigration failed with error Bad credentials.":
      translations.pl["Incorrect username or password."],
    "We Texted You": translations.pl["We Emailed You"],
    "Sign in": translations.pl["Sign In"],
  },
  pt: {
    "UserMigration failed with error Bad credentials.":
      translations.pt["Incorrect username or password."],
    "We Texted You": translations.pt["We Emailed You"],
    "Sign in": translations.pt["Sign In"],
  },
  zh: {
    "UserMigration failed with error Bad credentials.":
      translations.zh["Incorrect username or password."],
    "We Texted You": translations.zh["We Emailed You"],
    "Sign in": translations.zh["Sign In"],
  },
  sv: {
    "UserMigration failed with error Bad credentials.":
      translations.sv["Incorrect username or password."],
    "We Texted You": translations.sv["We Emailed You"],
    "Sign in": translations.sv["Sign In"],
  },
  tr: {
    "UserMigration failed with error Bad credentials.":
      translations.tr["Incorrect username or password."],
    "We Texted You": translations.tr["We Emailed You"],
    "Sign in": translations.tr["Sign In"],
  },
  ru: {
    // No ru translation for 'Incorrect username or password.'
    "UserMigration failed with error Bad credentials.":
      "Incorrect username or password.",
    "We Texted You": "We Emailed You",
    "Sign in": "Sign In",
  },
};
I18n.putVocabularies(dict);

// A lot of these auth events have been removed from the Hub API in Amplify v6, so we need to find a way to replace them.
Hub.listen("auth", ({ payload }) => {
  if (payload.event === "signIn") {
      trackRecommendedEvent("login", {
        method: "EmailAndPassword",
      });
    }
  if (
    payload.event === "signIn_failure" &&
    payload.message === "The OAuth response flow failed" &&
    payload.data &&
    payload.data.message &&
    payload.data.message.startsWith("Already+found+an+entry+for+username")
  ) {
    trackEvent("login", "signIn_failure", payload.message);
    alert(
      "An account already exists with that email address, Please sign in with google again.",
    );
    // Auth.federatedSignIn({ provider: 'Google' });
  } else if (payload.event === "signIn_failure") {
    trackEvent(
      "login",
      "signIn_failure",
      payload && payload.data ? payload.data.message : "failed to signin",
    );
  }

  if (
    payload.event === "cognitoHostedUI" &&
    payload.message.endsWith("has been signed in via Cognito Hosted UI")
  ) {
    trackRecommendedEvent("login", {
      method: payload.data?.username.startsWith("Wayside")
        ? "Wayside-SAML"
        : "Google",
    });
    const redirectPath = localStorage.getItem("nualang-auth-current-path");
    if (
      `${window.location.pathname}${window.location.search}` !== redirectPath
    ) {
      window.location.replace(redirectPath);
    }
  }

  if (payload.event === "forgotPassword") {
    trackEvent("login", "forgotPassword");
  }
  if (payload.event === "forgotPasswordSubmit") {
    trackEvent("login", "forgotPasswordSubmit");
  }
  if (payload.event === "forgotPasswordSubmit_failure") {
    trackEvent(
      "login",
      "forgotPasswordSubmit_failure",
      payload && payload.data
        ? payload.data.message
        : "failed to reset password",
    );
  }

  if (payload.event === "signUp") {
    trackRecommendedEvent("sign_up", {
      method: "EmailAndPassword",
    });
  }

  if (payload.event === "confirmSignUp") {
    trackEvent("signUp", "confirmSignUp");
  }
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 1000 * 5, // 5 minutes
    },
  },
});

export default function withRoot(Component) {
  return function WrapperComponent(props) {
    return (
      <QueryClientProvider client={queryClient} contextSharing={true}>
        {window.Cypress ? null : <ReactQueryDevtools />}
        <AppContextProvider>
          <UsersContextProvider>
            <CoursesContextProvider>
              <ClassroomsContextProvider>
                <BotsContextProvider>
                  <RoleplaysContextProvider>
                    <FeedbackContextProvider>
                      <DndProvider options={HTML5toTouch}>
                        <Component {...props} />
                        <Preview generator={generatePreview} />
                      </DndProvider>
                    </FeedbackContextProvider>
                  </RoleplaysContextProvider>
                </BotsContextProvider>
              </ClassroomsContextProvider>
            </CoursesContextProvider>
          </UsersContextProvider>
        </AppContextProvider>
      </QueryClientProvider>
    );
  };
}
